<template>
    <div>
        <DsListItem
            v-for="({ message, displayDate, project, author }, index) in comments"
            :key="index"
            border-style="full"
        >
            <template #leading>
                <router-link :to="{ name: 'user', params: { id: author.id || 0 } }">
                    <UserAvatar
                        :size="40"
                        :user="author"
                    />
                </router-link>
            </template>

            <template #title>
                {{ message }}
            </template>

            <template #description>
                <p style="margin: 0">
                    <router-link :to="{ name: 'user', params: { id: author.id || 0 } }">
                        {{ author.name }}
                    </router-link>

                    commented in

                    <router-link
                        :to="{ name: 'project', params: { id: project.id } }"
                        class="project-name"
                    >
                        {{ project.name }}
                    </router-link>
                    <span class="date-added"> {{ displayDate }}</span>.
                </p>
            </template>
            <router-link :to="{ name: 'user', params: { id: author.id || 0 } }">
                {{ author.name }}
            </router-link>
        </DsListItem>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { roles } from '@/components/project/constants';
import UserAvatar from '@/components/UserAvatar';

export default {
    components: {
        UserAvatar,
    },

    props: {
        comments: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            roles,
        };
    },

    computed: {
        ...mapGetters({
            getUser: 'users/getUser',
        }),
    },
};
</script>
