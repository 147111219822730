<template>
    <div class="login-page">
        <div v-if="authenticating" class="loading-spinner centered white">
            <DsSpinner />
        </div>

        <DsCard v-else class="login-box">
            <img
                src="/thryv-logo.png"
                alt=""
                width="200"
            />

            <h4>Hackathons</h4>
            <p>
                {{ newUser ? 'Sign up to join the hackathon, spark ideas, and build something amazing with your team!' : 'Log in to participate, collaborate, and innovate with your team!' }}
                <DsLink @click="toggleAction">
                    {{ newUser ? 'Already have an account?' : 'Need an account?' }}
                </DsLink>
            </p>

            <DsFilledButton
                v-if="user.uid"
                @click="logout"
            >
                Log out
            </DsFilledButton>

            <template v-else>
                <DsInlineAlert
                    v-if="error"
                    type="warning"
                    leading-icon
                >
                    {{ error }}
                </DsInlineAlert>

                <form style="width: 100%;" @submit.prevent="submit">
                    <DsInputField
                        v-model.trim="email"
                        required
                        label="Email"
                    />

                    <DsInputField
                        v-model.trim="password"
                        required
                        type="password"
                        label="Password"
                        :minlength="6"
                        :maxlength="4096"
                        :invalid="!isValidPassword"
                    >
                        <template #help>
                            <small>Must include an uppercase letter, lowercase letter, and a number.</small>
                        </template>
                    </DsInputField>

                    <DsFilledButton type="submit" :loading="submitting">
                        {{ buttonLabel }}
                    </DsFilledButton>
                </form>

                or

                <div>
                    <img
                        src="@/assets/images/sign-in-google.png"
                        @click="loginWithGoogle"
                    />
                </div>
            </template>
        </DsCard>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
    data() {
        return {
            loading: false,
            newUser: false,
            authenticating: false,
            errorCode: null,
            submitting: false,
            email: '',
            password: '',
        };
    },

    mounted () {
        if (this.user.uid) this.$router.push({ name: 'home' });
    },

    watch: {
        'user.uid': function (value) {
            if (value) this.checkAccess();
        },
    },

    computed: {
        ...mapState({
            googleToken: ({ auth }) => auth.googleToken,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            guestsList: 'users/guestsList',
        }),

        buttonLabel() {
            return this.newUser ? 'Create account' : 'Login';
        },

        isValidPassword() {
            if (!this.newUser) return true;

            const hasUppercase = /[A-Z]/.test(this.password);
            const hasLowercase = /[a-z]/.test(this.password);
            const hasNumber = /\d/.test(this.password);
            const isValidLength = this.password.length >= 6 && this.password.length <= 4096;

            return hasUppercase && hasLowercase && hasNumber && isValidLength;
        },

        error() {
            if (!this.errorCode) return null;

            if (this.errorCode === 'auth/user-disabled') return 'This account has been disabled. Please contact support.';
            if (this.errorCode === 'auth/user-not-found') return 'No account found with this email.';
            if (this.errorCode === 'auth/wrong-password') return 'Invalid credentials. Please try again.';
            if (this.errorCode === 'auth/email-already-in-use') return 'An account with this email already exists.';
            if (this.errorCode === 'auth/operation-not-allowed') return 'This action is currently not allowed. Please contact support.';
            if (this.errorCode === 'auth/weak-password') return 'Your password is too weak. Please choose a stronger one.';

            return 'An error occurred';
        },
    },

    methods: {
        toggleAction() {
            this.newUser = !this.newUser;
            this.errorCode = null;
        },

        submit() {
            return this.newUser ? this.createUserWithEmail() : this.loginWithEmail();
        },

        async createUserWithEmail() {
            this.submitting = true;

            try {
                const result = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);

                const { creationTime, lastSignInTime } = result.user.multiFactor.user.metadata;

                const user = {
                    ...result.user.multiFactor.user,
                    name: result.user.multiFactor.user.displayName,
                    creationTime,
                    lastSignInTime,
                };

                const db = firebase.firestore();

                await db.collection('users').doc(result.user.uid).set(user);

                const newDoc = await db.collection('users').doc(result.user.uid).get();

                this.$store.commit('auth/SET_USER', {
                    isAdmin: newDoc.data()?.isAdmin,
                    ...user,
                });

                this.submitting = false;
            } catch (error) {
                this.errorCode = error.code;
                this.submitting = false;
            }
        },

        async loginWithEmail() {
            try {
                this.submitting = true;
                const { user } = await firebase.auth().signInWithEmailAndPassword(this.email, this.password);

                this.$store.commit('auth/SET_USER', user);
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
                this.errorCode = error.code;
            }
        },

        async checkAccess() {
            this.loading = true;

            const userEmail = this.user?.email;

            if (userEmail?.endsWith('@keap.com') || userEmail?.endsWith('@thryv.com')) {
                this.$router.push({ name: 'home' });

                return;
            }

            try {
                await this.$store.dispatch('users/LOAD_GUEST_LIST');

                if (this.guestsList.includes(userEmail)) {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$error({ message: 'Access restricted.', bottom: true });
                    this.loading = false;
                    this.logout();
                }
            } catch (e) {
                //
            }
        },

        logout() {
            firebase.auth().signOut().then(() => {
                this.$store.commit('auth/SET_USER', { email: '' });
                this.$store.commit('auth/SET_GOOGLE_TOKEN', '');
                this.$router.push({ name: 'login' });
            }).catch(() => {});
        },

        async loginWithGoogle() {
            this.loading = true;

            const { currentUser } = firebase.auth();

            if (this.googleToken && currentUser) return;

            const provider = new firebase.auth.GoogleAuthProvider();

            try {
                this.authenticating = true;

                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

                const result = await firebase.auth().signInWithPopup(provider);

                this.authenticating = Boolean(result.user);

                if (!result.credential || !result.user) {
                    this.$store.commit('auth/SET_GOOGLE_TOKEN', '');

                    return;
                }

                if (!this.googleToken && result.credential?.idToken) {
                    this.$store.commit('auth/SET_GOOGLE_TOKEN', result.credential.idToken);
                    this.$store.commit('auth/SET_UNAUTHENTICATED_COUNT', 0);
                }

                const db = firebase.firestore();

                const { creationTime, lastSignInTime } = result.user.metadata;
                const { email, name, picture } = result.additionalUserInfo.profile;

                let user = {
                    uid: result.user.uid,
                    email,
                    name,
                    photoUrl: picture,
                    creationTime,
                    lastSignInTime,
                };

                try {
                    const doc = await db.collection('users').doc(result.user.uid).get();

                    if (doc.exists && doc.data()) {
                        user = {
                            ...user,
                            ...doc.data(),
                        };
                    } else {
                        await db.collection('users').doc(result.user.uid).set(user);

                        const newDoc = await db.collection('users').doc(result.user.uid).get();

                        user = {
                            ...user,
                            isAdmin: newDoc.data()?.isAdmin,
                        };
                    }
                } catch (e) {
                    // console.error('Firestore error:', e);
                }

                this.$store.commit('auth/SET_USER', user);
            } catch (error) {
                console.error('Google auth failed:', error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .login-page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: $spacing-500;
    }

    .login-box {
        display: flex;
        width: 500px;
        max-width: 100%;
        padding: $spacing-300;
        gap: $spacing-200;
        flex-direction: column;
    }
</style>
